import {toRefs,reactive} from "vue";
import axios from 'axios'
import jwtDecode from 'jwt-decode'

const state = reactive<{ user: any;authorized: boolean; user_data: any; error: any; token: any; mercureToken: any; twoFactor: any; initialized: boolean;email: any}>({
    user: null,
    user_data: {},
    initialized: false,
    authorized: false,
    token: null,
    mercureToken: null,
    error: null,
    twoFactor: null,
    email: null
})


 export default function() {
    // const axios = axios();
    const logout = () =>{
      sessionStorage.removeItem('token')
      sessionStorage.removeItem('user_data')
      sessionStorage.removeItem('mercureToken')

        state.initialized=false
        state.user=null
        state.user_data={}
        state.token=null,
        state.twoFactor=null
    }

    

    const login = async(email: any,password: any) =>{
        const url = process.env.VUE_APP_API_URL+'/authentication_token'
        return new Promise((resolve, reject) =>  {
            axios
          .post(url, {email:email,password:password})
          .then(async(response: { data: { token: any,mercureToken: any } }) => {
              console.log(response.data.token)
              state.token=response.data.token
              sessionStorage.setItem('token',response.data.token)
              sessionStorage.setItem('user_data',jwtDecode(state.token))
              sessionStorage.setItem('mercureToken',response.data.mercureToken)
              state.mercureToken=response.data.mercureToken
            if (state.token) {
              state.user_data=jwtDecode(state.token)
            }
            state.initialized=true
            state.user=true

            // console.log(store.token.value)
            // const config = {
            //     headers: { 'Authorization': 'Bearer '+response.data.token }
            // };
      
            // console.log(config)
            console.log('auth',state)
            resolve(state)
          })
          .catch(() => {
            state.error= 'Incorrect username or password'
            reject(state)
          })
        })
    }
    const authCheck = () => {
        return new Promise((resolve)=>{
            //does mt token exist and is it valid
            // state.initialized=true;
            
            if(!sessionStorage.getItem('token')){
              resolve(false)
            }
            state.token=sessionStorage.getItem('token')
            state.user_data=jwtDecode(state.token)

            if(state.token && state.user_data.exp > (Date.now() / 1000)){
              state.user=true
              resolve(true)
              }
            else resolve(false)
            // console.log(state)
            // state.initialized
            
        });
    }


    const setAuthCode = (twofactor: any) =>{
      state.twoFactor=twofactor
    }
    
    const twoFactorCheck = async (twoFactorCode: number) => {
      const url = process.env.VUE_APP_API_URL + '/api/owners/2Fa'
      console.log(state.token)
      return new Promise((resolve, reject) => {
        axios
          .post(url, { authCode: twoFactorCode }, { headers: { "Authorization": "Bearer " + state.token } })
          .then(async (response) => {
            state.twoFactor=twoFactorCode
            resolve(response)
          }).catch((e) => {
            console.log(e)
            logout()
            state.error = 'Incorrect auth code'
            reject(state)
          })
      })
    }

    const forgot = (email: any) =>{
      const url = process.env.VUE_APP_API_URL+'/forgot_password'
      return new Promise((resolve, reject) => {
          axios
        .post(url, {email:email})
        .then(() => {
          state.error= ''
          resolve(state)
        })
        .catch(e => {
            console.log(e.response.data,'reg err')
          state.error= e.response.data
          reject(state)
        })
      })
    }

    const register = (name: any, email: any, password: any,locale: string,registrationId: any,address: any,phone: any) => {
      const url = process.env.VUE_APP_API_URL+'/register'
      return new Promise((resolve, reject) => {
          axios
        .post(url, {name:name,email:email,plainPassword:password,locale:locale, registrationId:registrationId,address:address,phone:phone,'user_type':'company'})
        .then(() => {
          state.error= ''
          resolve(state)
        })
        .catch(e => {
            console.log(e.response.data,'reg err')
          state.error= e.response.data
          reject(state)
        })
      })
  }

  const changePassword = async (oldPassword: string, newPassword: string) => {
    console.log(oldPassword,newPassword,state.token)
    const url = process.env.VUE_APP_API_URL+'/api/owners/resetPassword'
      return new Promise((resolve, reject) => {
          axios
        .post(url, {password:oldPassword,newPassword:newPassword},{headers: {
          "Authorization": "Bearer "+state.token,
        }})
        .then(() => {
          state.error= ''
          resolve(state)
        })
        .catch(e => {
            console.log(e.response.data,'reg err')
          state.error= e.response.data
          reject(state)
        })
      })
    return true
  }

    state.token=sessionStorage.getItem('token')
    state.authorized = state.token && state.user_data.exp > Date.now() / 1000

    return {
        ...toRefs(state),
        login,
        logout,
        forgot,
        register,
        authCheck,
        setAuthCode,
        changePassword,
        twoFactorCheck
    };
 }