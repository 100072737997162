import { createApp } from 'vue'

import App from './App.vue'
import router from './router';

import 'bootstrap/scss/bootstrap.scss'
import bootstrap from "bootstrap";
import './/scss/custom.scss';
import { createI18n } from 'vue-i18n'
import { messages, defaultLocale } from "@/i18n";


const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
const tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
  return new bootstrap.Tooltip(tooltipTriggerEl)
})

const i18n = createI18n({
  legacy: false, // you must set `false`, to use Compostion API
  locale: 'fi', // set locale
  fallbackLocale: 'en', // set fallback locale
  messages, // set locale messages
  // If you need to specify other options, you can set other options
  // ...
})

const app = createApp(App).use(router).use(i18n).mount('#app');
