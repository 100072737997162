import { Locales } from "./locales";

import en from "./en.json";
import fi from "./fi.json";

export const messages = {
  [Locales.EN]: en,
  [Locales.FI]: fi
};

export const defaultLocale = Locales.EN;
