import { RouteRecordRaw, createRouter, createWebHashHistory } from 'vue-router';

import auth from '../modules/auth'

const state = auth();

const routes: Array<RouteRecordRaw> = [
  {
    path: '',
    redirect: '/login',
    name: 'Login'
  },
  {
    path: '/login',
    component: () => import ('../views/Login.vue')
  },
  {
    path: '/forgot',
    component: () => import ('../views/Forgot.vue')
  },
  {
    path: '/thanks',
    component: () => import ('../views/Thanks.vue')
  },
  {
    path: '/register',
    component: () => import ('../views/Register.vue')
  },
  {
    path: '/dashboard/:serial?',
    name: 'dashboard',
    component: () => import ('../views/Dashboard.vue'),
    meta:{
      requiresAuth: true
    }
  },
  {
    path: '/admin/approve',
    name: 'admin_approve',
    component: () => import ('../views/Admin/ApproveUser.vue'),
    meta:{
      requiresAuth: true
    }
  },
  {
    path: '/admin/pressure',
    name: 'admin_pressure',
    component: () => import ('../views/Admin/Pressure.vue'),
    meta:{
      requiresAuth: true
    }
  },
  {
    path: '/admin/device_log',
    name: 'admin_device_log',
    component: () => import ('../views/Admin/DeviceLog.vue'),
    meta:{
      requiresAuth: true
    }
  },
  {
    path: '/admin/config',
    name: 'admin_config',
    component: () => import ('../views/Admin/Config.vue'),
    meta:{
      requiresAuth: true
    }
  },
  {
    path: '/admin/settings',
    name: 'admin_settings',
    component: () => import ('../views/Admin/Settings.vue'),
    meta:{
      requiresAuth: true
    }
  },
  {
    path: '/admin/transfer',
    name: 'admin_transfer',
    component: () => import ('../views/Admin/Transfer.vue'),
    meta:{
      requiresAuth: true
    }
  },
  {
    path: '/admin/online',
    name: 'admin_online',
    component: () => import ('../views/Admin/Online.vue'),
    meta:{
      requiresAuth: true
    }
  },
  {
    path: '/admin/rawdata',
    name: 'rawdata',
    component: () => import ('../views/Admin/RawData.vue'),
    meta:{
      requiresAuth: true
    }
  },
  {
    path: '/admin/device_settings',
    name: 'device_settings',
    component: () => import ('../views/Admin/ViewDeviceSettings.vue'),
    meta:{
      requiresAuth: true
    }
  },
  {
    path: '/admin/types',
    name: 'admin_types',
    component: () => import ('../views/Admin/DeviceType.vue'),
    meta:{
      requiresAuth: true
    }
  },
  {
    path: '/admin/report',
    name: 'admin_report',
    component: () => import ('../views/Admin/Report.vue'),
    meta:{
      requiresAuth: true
    }
  },
  {
    path: '/admin/inventory',
    name: 'admin_inventory',
    component: () => import ('../views/Admin/Inventory.vue'),
    meta:{
      requiresAuth: true
    }
  },
  {
    path: '/company/device',
    name: 'company_device',
    component: () => import ('../views/Company/Device.vue'),
    meta:{
      requiresAuth: true
    }
  },
  {
    path: '/company/billing',
    name: 'company_billing',
    component: () => import ('../views/Company/Billing.vue'),
    meta:{
      requiresAuth: true
    }
  },
  {
    path: '/company/alarm',
    name: 'company_alarm',
    component: () => import ('../views/Company/Alarm.vue'),
    meta:{
      requiresAuth: true
    }
  },
  {
    path: '/company/profile',
    name: 'company_profile',
    component: () => import ('../views/Company/Profile.vue'),
    meta:{
      requiresAuth: true
    }
  },
  {
    path: '/company/resetpassword',
    name: 'reset_password',
    component: () => import ('../views/Company/ResetPassword.vue'),
    meta:{
      requiresAuth: true
    }
  },
  {
    path: '/company/users',
    name: 'users',
    component: () => import ('../views/Company/User.vue'),
    meta:{
      requiresAuth: true
    }
  },
  {
    path: '/config/twofactor',
    name: 'twofactor',
    component: () => import ('../views/Config/TwoFactor.vue'),
    meta:{
      requiresAuth: true
    }
  },
  {
    path: '/config/update',
    name: 'update',
    component: () => import ('../views/Config/UpdateSettings.vue'),
    meta:{
      requiresAuth: true
    }
  },
  {
    path: '/config/wizard',
    name: 'deploy',
    component: () => import ('../views/Config/Deploy.vue'),
    meta:{
      requiresAuth: true
    }
  },

]
const router = createRouter({
    history: createWebHashHistory(),
    routes
  })
  
  

router.beforeEach(async(to: any, from: any, next: any) => {
    console.log(to)
  await state.authCheck().then((e)=>{
    console.log(e,'auth')
      if(e!=true){
        console.log('false')
        state.logout()
        // next({name: 'Login', replace: true})
        // return true
      }

      
    })
    
  if (!state.user.value&&to.meta.requiresAuth) {
    
    next({name: 'Login', replace: true})
    
    return true
  }
  if(typeof state.user_data.value.roles!='undefined'){
    if(state.user_data.value.roles.indexOf("ROLE_CONFIG") != -1&&to.name!='twofactor'&&to.name!='logout') {
      console.log('is config user',state,to)
      if(!state.twoFactor.value){
        next({name: 'twofactor', replace: true})
        return 
      }
    }

    window.history.forward()
    window.history.pushState({page: 1}, "", "");
    window.onpopstate = function(event: any) {

      // "event" object seems to contain value only when the back button is clicked
      // and if the pop state event fires due to clicks on a button
      // or a link it comes up as "undefined" 
    
      if(event){
        router.push({ path: "/dashboard", replace: true });
        window.location.reload();
      }
      else{
        // Continue user action through link or button
      }
    }

    next();
    return true
  }
  next();

});


export default router
